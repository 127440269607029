import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ value, options, onChange, error }) => {
  return (
    <div className="form-group">
      <label>
        Element Type
        <span className="required" style={{ color: "red" }}>
          {" "}
          *{" "}
        </span>
        :
      </label>
      <Select
        value={value || null}
        options={options}
        onChange={onChange}
        components={{
          Option: ({ data, innerRef, innerProps }) => (
            <div
              ref={innerRef}
              {...innerProps}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                cursor: "pointer",
                backgroundColor: innerProps.isFocused ? "#f0f0f0" : "white",
              }}
            >
              {data.image && (
                <img
                  src={data.image}
                  alt={data.label}
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
              )}
              {data.label}
            </div>
          ),
          SingleValue: ({ data }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              {data.image && (
                <img
                  src={data.image}
                  alt={data.label}
                  style={{ width: 60, height: 60, marginRight: 10 }}
                />
              )}
              {data.label}
            </div>
          ),
        }}
      />
      {error && <div className="errorcate">{error}</div>}
    </div>
  );
};

export default CustomSelect;
