import React, { Component } from "react";
import axios from "axios";
import { apiUrl} from'../Config/Config';

class ImageCropper extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef(); // Reference for the image element
    this.canvasRef = React.createRef(); // Reference for the canvas element
  }

  componentDidMount() {
    const image = this.imageRef.current;

    // Once the image loads, crop it to remove empty spaces
    image.onload = () => {
      this.trimImage(image);
    };
  }

  trimImage = (image) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Draw the full image on an off-screen canvas
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    ctx.drawImage(image, 0, 0);

    // Get image pixel data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;

    // Find cropping bounds
    let top = canvas.height;
    let bottom = 0;
    let left = canvas.width;
    let right = 0;

    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const index = (y * canvas.width + x) * 4; // RGBA index

        // Check if the pixel is not transparent (alpha > 0)
        if (pixels[index + 3] > 0) {
          top = Math.min(top, y);
          bottom = Math.max(bottom, y);
          left = Math.min(left, x);
          right = Math.max(right, x);
        }
      }
    }

    // Calculate the trimmed width and height
    const trimmedWidth = right - left + 1;
    const trimmedHeight = bottom - top + 1;

    // Draw the trimmed image on the visible canvas
    const outputCanvas = this.canvasRef.current;
    const outputCtx = outputCanvas.getContext("2d");

    outputCanvas.width = trimmedWidth;
    outputCanvas.height = trimmedHeight;

    outputCtx.drawImage(
      canvas, // Source canvas
      left,
      top,
      trimmedWidth,
      trimmedHeight, // Source crop area
      0,
      0,
      trimmedWidth,
      trimmedHeight // Destination canvas area
    );
    setTimeout(()=>{
      this.handleBlobHere()
    },1000)
  };

  handleBlobHere = async () => {
    const canvas = this.canvasRef.current;

    // Convert the canvas content to a Blob
    canvas.toBlob(
      (blob) => {
        if (blob) {
          // Set the file extension based on the image type
          const extension = "png"; // You can dynamically get the extension if needed (e.g., 'jpg', 'jpeg', etc.)

          let mimeType = "image/png"; // Default to PNG
          if (extension === "jpeg" || extension === "jpg") mimeType = "image/jpeg";
          else if (extension === "avif") mimeType = "image/avif";

          this.props.onTrimmedImage(blob,extension);

         
        }
      },
      "image/png" // Specify the format you want the canvas to be converted to
    );
  };

  handleUpload = async () => {
    const canvas = this.canvasRef.current;

    // Convert the canvas content to a Blob
    canvas.toBlob(
      (blob) => {
        if (blob) {
          // Set the file extension based on the image type
          const extension = "png"; // You can dynamically get the extension if needed (e.g., 'jpg', 'jpeg', etc.)

          let mimeType = "image/png"; // Default to PNG
          if (extension === "jpeg" || extension === "jpg") mimeType = "image/jpeg";
          else if (extension === "avif") mimeType = "image/avif";

          // Create FormData to upload the cropped image
          const formData = new FormData();
          formData.append("image", blob, `trimmed-image.${extension}`);

          // Make the Axios POST request to upload the cropped image
          axios
            .post(apiUrl+"configureproduct/tryImageuplode", formData)
            .then((response) => {
              console.log("Upload successful", response);
            })
            .catch((error) => {
              console.error("Upload failed", error);
            });
        }
      },
      "image/png" // Specify the format you want the canvas to be converted to
    );
  };

  render() {
    return (
      <div className="image-view-container"  >
        {/* Hidden image for processing */}
        <img
          ref={this.imageRef}
          src={this.props.imagePreview} // Use the passed image preview
          crossOrigin="anonymous"
          alt="Source"
          style={{ display: "none" }}
        />

        {/* Canvas to display the trimmed image */}
        <canvas ref={this.canvasRef} style={{ border: "1px solid #ccc" }} />

        {/* Button to trigger upload */}
        {/* <button onClick={this.handleUpload}>Upload Trimmed Image</button> */}
      </div>
    );
  }
}

export default ImageCropper;
