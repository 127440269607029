import React from "react";
import $ from 'jquery';
import axios from "axios";
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import ImageCropper from './ImageCropper';


class Addimageinputsimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [
        {
          images: { thumb: [], original: [] },
          category_product_name : '',
          category_product_price: '',
          lappriority: '',
          category: '',
          elementtype: '',
        }
      ],
      removekey: null, 
      domain_id: null, 
      removetryoutrow:false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  



  handleImageChange = (e, index) => {
    const { name, files } = e.target;
    const fileType = name.includes("thumb") ? "thumb" : "original";
    const file = files[0];
  
    if (!file) {
      console.error("No file selected!");
      return;
    }
  
    const fileNameWithExtension = file.name;
    const fileName = fileNameWithExtension.split(".").slice(0, -1).join("."); // Get name without extension
    const extension = file.name.split(".").pop(); // Get file extension
  
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState((prevState) => {
        const updatedRows = [...prevState.rows];
  
        
        if (!updatedRows[index]) {
          updatedRows[index] = { images: {}, image_previews: {}, configImages: {}, fileExtensions: {} };
        }
  
       
        updatedRows[index].images = updatedRows[index].images || {};
        updatedRows[index].image_previews = updatedRows[index].image_previews || {};
        updatedRows[index].fileExtensions = updatedRows[index].fileExtensions || {};
  

        updatedRows[index].images[fileType] = file;
        updatedRows[index].image_previews[fileType] = reader.result;
        updatedRows[index].image_name = fileName;
        updatedRows[index].fileExtensions[fileType] = extension;
  
        return { rows: updatedRows };
      });
    };
  
    reader.readAsDataURL(file);
  };
  


handleTrimmedImage = (trimmedImage, ext, index, fileType) => {
  this.setState((prevState) => {
    const updatedRows = [...prevState.rows];

    if (!updatedRows[index]) {
      console.error(`Row at index ${index} is undefined`);
      return null;
    }

    if (!updatedRows[index].configImages) {
      updatedRows[index].configImages = {};
    }

    
    updatedRows[index].configImages[fileType] = trimmedImage;
    updatedRows[index].fileExtensions[fileType] = ext; // Set PNG as extension

    console.log("Updated Rows with Cropped Image:", updatedRows);
    return { rows: updatedRows };
  });
};



onSelectImage = (arg, e, index) => {
  const file = e.target.files[0];

  if (!file) {
    console.error('No file selected!');
    return;
  }

  const fileNameWithExtension = file.name;
  const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.'); // Get name without extension
  const extension = file.name.split('.').pop();

  let reader = new FileReader();
  reader.onloadend = () => {
    this.setState((prevState) => {
      const updatedRows = [...prevState.rows];

      if (!updatedRows[index]) {
        updatedRows[index] = { images: {}, image_previews: {}, configImages: {} };
      }

      updatedRows[index].images[arg] = file;
      updatedRows[index].image_previews[arg] = reader.result;
      updatedRows[index].image_name = fileName;
      updatedRows[index].fileExtension = extension;

      return { rows: updatedRows };
    });
  };

  reader.readAsDataURL(file);
};



  componentDidUpdate(prevProps, prevState) {
		if (this.props.shouldRerender !== prevProps.shouldRerender && this.props.shouldRerender) {
			
			this.setState({
        rows: [
          {
            images: { thumb: [], original: [] },
            category_product_name : '',
            category_product_price: '',
            lappriority: '',
            category: '',
            elementtype: '',
          }
        ],
				
			});
			
			
		}
	}
  handleInputChange(event, rowIndex) {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const newRows = [...prevState.rows];
      newRows[rowIndex][name] = value;
      return { rows: newRows };
    }, () => {
      console.log(this.state,'this is private')
      this.props.onChangeChild(this.state); // Send all values to the parent
    });
  }

  handleChange(selectedOption, rowIndex) {
    this.setState((prevState) => {
      const newRows = [...prevState.rows];
      newRows[rowIndex].selectedOption = selectedOption;
      newRows[rowIndex].laptype = selectedOption.value;
      return { rows: newRows };
    }, () => {
      this.props.onChangeChild(this.state); // Send all values to the parent
    });
  }

  addRow = () => {
    if (this.state.rows.length < 3) { // Maximum 3 rows
      this.setState((prevState) => ({
        rows: [...prevState.rows, { images: [], xaxis: '', yaxis: '', lappriority: '', category: '', laptype: 'overlap', selectedOption: { value: 'overlap', label: 'Overlap' } }]
      }));
    }
  }

  cancelConfirmPopup = (index, domainID) => {
    this.setState({ removekey: index, domain_id: domainID,removetryoutrow:true });
    
  }

 

  cancelElementConfirm = (confirmstatus) => {
    if (confirmstatus === 'yes') {
      this.setState((prevState) => {
        const newRows = [...prevState.rows];
        newRows.splice(this.state.removekey, 1); // Remove the row at the specified index
        return { rows: newRows, removekey: null, domain_id: null };
      });
    }

    this.setState({ removetryoutrow: false});
    
    
  }

  render() {
    const showAddRowButton =  this.state.rows.length < 3;
   
    const showRemoveRowButton =  this.state.rows.length > 1;
    
    return (
      <>
        {this.state.rows.map((row, index) => (
          <div key={index} className="form-row">
            {/* {this.props.trialon === 'element' && ( */}
             
                <div className="form-left">
                <div className="form-group">
                    <label>Element Type<span class="required" style={{ color: "red" }} > * </span> : </label>
                    <Select
                      value={this.state.rows[index].selectedOption ? this.state.rows[index].selectedOption : null}
                      options={lang.common.element_type} // Assuming it's in { value, label } format
                      onChange={(selectedOption) => this.handleChange(selectedOption, index)} // Use 'index' instead of 'rowIndex'
                    />
                      <div className="errorcate"></div>
								</div>
                
                    <label>Upload Thumb Image:</label>
                    <input
                       type="file"
                       className="form-control"
                        name={`thumb-${index}`}
                        onChange={(e) => this.handleImageChange(e, index)}
                    />
                             {/* <ul className="carimagelisting">
                               {row.images.thumb && row.images.thumb instanceof File && (
                                <li className="thumb">
                                    <img src={URL.createObjectURL(row.images.thumb)} alt={row.images.thumb.name} />
                                </li>
                              )}
                            </ul> */}
                            {row.image_previews?.thumb && (
                              <ImageCropper
                                imagePreview={row.image_previews.thumb}
                                index={index} 
                                fileType="thumb" 
                                imageName={row.image_name} 
                                onTrimmedImage={this.handleTrimmedImage}
                              />
                            )}
                      <label>Upload Original Image:</label>
                      <input
                        type="file"
                        className="form-control"
                        name={`original-${index}`}
                        onChange={(e) => this.handleImageChange(e, index)}
                      />
                    {/* <ul className="carimagelisting">
                     
                      {row.images.original && row.images.original instanceof File && (
                        <li className="thumb">
                            <img src={URL.createObjectURL(row.images.original)} alt={row.images.original.name} />
                        </li>
                      )}
                    </ul> */}
                    {row.image_previews?.original && (
                      <ImageCropper
                        imagePreview={row.image_previews.original}
                        index={index} // Pass row index
                        fileType="original"
                        imageName={row.image_name} 
                        onTrimmedImage={this.handleTrimmedImage}
                      />
                    )}
                </div>
              <div className="form-right">
                 <div className="form-group">
                    <label>Element name:</label>
                    <input
                      type="text"
                      name='category_product_name'
                      value={row.category_product_name}
                      onChange={(e) => this.handleInputChange(e, index)}
                      required
                      onInvalid={(e) => e.target.setCustomValidity('Please enter a value for the category')}
                      onInput={(e) => e.target.setCustomValidity('')}
                    />
                  </div>
                  <div className="form-group">
                    <label>Element price:($)</label>
                    <input
                      type="text"
                      name="category_product_price"
                      value={row.category_product_price}
                      onChange={(e) => this.handleInputChange(e, index)}
                      required
                      onInvalid={(e) => e.target.setCustomValidity('Please enter a value for the price')}
                      onInput={(e) => e.target.setCustomValidity('')}
                    />
                  </div>
                  <div className="form-right config-ringht">
                    {showRemoveRowButton && (
                      <button  onClick={this.cancelConfirmPopup.bind(this, index, row.domain_id)} type="button" >-</button>
                    )}
                    {showAddRowButton && (
                        <button onClick={this.addRow} type="button">+</button>
                    )}
                  </div>
                </div>
          </div>
        ))}
       
        <div  className={`confirm-action  ${this.state.removetryoutrow ? 'show' : ''}`}>
          <p>Do you want to remove your  Element? </p>
          <button onClick={this.cancelElementConfirm.bind(this, "yes")} className="btn">Yes</button>
          <button onClick={this.cancelElementConfirm.bind(this, "no")} className="btn">No</button>
        </div>
      </>
    );
  }
}

export default Addimageinputsimple;
