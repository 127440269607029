import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Background from '../../common/images/side-bg.jpg';
import loginImg from "../../common/images/login-logo.png"
class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'nav-item has-sub active'
            }else{
            status = 'nav-item has-sub enable';
        }
        return status;
    }
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
  <div className="navbar-header expanded">
    <ul className="nav navbar-nav flex-row position-relative">
      <li className="nav-item mr-auto">
        <a className="navbar-brand" href="#">
        {/* <img src={loginImg} /> */}
          <h3 className="brand-text">Admin</h3>
        </a>
      </li>
      <li className="nav-item d-md-none"><a href="javascript:void(0)" className="nav-link close-navbar mb-side-nav"><i className="ft-x"></i></a></li>
    </ul>
  </div>
  <div className="navigation-background" style={{background: `url(${Background})`}}></div>
  <div className="main-menu-content ps ps--active-y" style={{height: "587px;"}}>
  <Scrollbars className="custom-scroll-wrap">
    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <li className={this.activeMenu('dashboard')}>
        <a href="/dashboard">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Dashboard</span>
        </a>
      </li>
      
      {this.state.admin_id == 1 ?<li className={this.activeMenu('companylist')}>
					<a title="Company" href="/company">
						<i className="ft-company" aria-hidden="true"></i>
						<span>Companies</span>
					</a>
		        </li>:''} 
      
      <li className={this.activeMenu('user')}>
        <a title="User" href="/user">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Users</span>
        </a>
      </li>
      <li className={this.activeMenu('storelist')}>
        <a title="store" href="/store">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Stores</span>
        </a>
      </li>
      <li className={this.activeMenu('Dealer')}>
        <a title="Dealer" href="/dealer">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Dealers</span>
        </a>
      </li>
      <li className={this.activeMenu('tabmanagement')}>
        <a href="/tabmanagement">
          <i className="ft-tab-management"></i>
          <span className="menu-title" data-i18n="">Tab Management</span>
        </a>
      </li>

      <li className={this.activeMenu('dashboardmanagement')}>
					<a title="Dashboard Management Builder" href="/dashboardmanagement">
						<i className="ft-server"></i>
						<span>Dashboard Management</span>
					</a> 
				</li>
        <li className={this.activeMenu('shortcuticon')}>
          <a title="Shortcut icon" href="/shortcuticon">
            <i className="ft-memb-category"></i>
            <span>Shortcut Icons</span>
          </a>
		  </li>
        <li className={this.activeMenu('abusiveword')}>
          <a href="/abusivewords">
            <i className="ft-edit" aria-hidden="true"></i>
            <span className="menu-title" data-i18n="">Abusive Words</span>
          </a>
        </li>

      <li className={this.activeMenu('coupon')}>
        <a title="coupon" href="/coupon">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Coupons</span>
        </a>
      </li>
      
      <li className={this.activeMenu('productcategory')}>
					<a href="/productcategory">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Product Categories</span>
					</a>
      </li>
      <li className={this.activeMenu('product')}>
        <a href="/product">
          <i className="ft-product" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Products</span>
        </a>
      </li>
      <li className={this.activeMenu('config-product')}>
        <a href="/config-product">
          <i className="ft-config-product" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Configure Products</span>
        </a>
      </li>
      <li className={this.activeMenu('config-product-items')}>
        <a href="/config-product-items">
          <i className="ft-config-product" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Configure Products Items</span>
        </a>
      </li>
      <li className={this.activeMenu('advertisementcategory')}>
					<a title="Dashboard Management Builder" href="/advertisementcategory">
						<i className="ft-ads"></i>
						<span>Advertisement Categories</span>
					</a>
				</li>
        <li className={this.activeMenu('advertisement')}>
        <a title="Advertisement" href="/advertisement">
          <i className="ft-ads"></i>
          <span className="menu-title" data-i18n="">Advertisement</span>
        </a>
      </li>
      <li className={this.activeMenu('cms')}>
					<a href="/Cms">
						<i className="ft-offer" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Offer Pages</span>
					</a>
				</li>
        <li className={this.activeMenu('GuideCategory')}>
					<a href="/GuideCategory">
						<i className="ft-guide" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Guide Category</span>
					</a>
				</li>
        <li className={this.activeMenu('GuideSubCategory')}>
					<a href="/GuideSubCategory">
						<i className="ft-guide" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Guide Sub Category</span>
					</a>
				</li>
        <li className={this.activeMenu('guide')}>
					<a href="/Guide">
						<i className="ft-guide" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Guide</span>
					</a>
				</li>
        <li className={this.activeMenu('rolemanagement')}>
					<a href="/rolemanagement">
						<i className="ft-role" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Role Management</span>
					</a>
				</li>
        <li className={this.activeMenu('userrolemanagement')}>
					<a href="/userrolemanagement">
						<i className="ft-role" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">User Role</span>
					</a>
				</li>
      <li className={this.activeMenu('Color')}>
        <a href="/Color">
          <i className="ft-gallery"></i>
          <span className="menu-title" data-i18n="">Colors</span>
        </a>
      </li>
      <li className={this.activeMenu('brand')}>
        <a href="/brand">
          <i className="ft-brand" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Brands</span>
        </a>
      </li>
      <li className={this.activeMenu('producthistory')}>
        <a href="/producthistory">
          <i className="ft-config-product" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Configure Orders</span>
        </a>
      </li>
      <li className={this.activeMenu('orders')}>
        <a href="/orders">
          <i className="ft-server" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Orders</span>
        </a>
      </li>
       {/* <li className={this.activeMenu('plugincontent')}>
					<a href="/plugincontent">
						<i className="ft-edit" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Plugin Content</span>
					</a>
				</li> */}

    
<li className={this.activeMenu('MembershipCategory')}>
        <a href="/membershipcategory">
          <i className="ft-membership" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Membership Categories</span>
        </a>
      </li>

      <li className={this.activeMenu('membership')}>
        <a href="/membership">
          <i className="ft-membership"></i>
          <span className="menu-title" data-i18n="">Memberships</span>
        </a>
      </li>
 {/*      <li className={this.activeMenu('gallery')}>
        <a href="/gallery">
          <i className="ft-gallery"></i>
          <span className="menu-title" data-i18n="">Gallery</span>
        </a>
      </li> */}
    
      <li className={this.activeMenu('FaqCategory')}>
        <a href="/Faqcategory">
          <i className="ft-faq-category" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Faq Categories</span>
        </a>
      </li>

      <li className={this.activeMenu('Faq')}>
        <a href="/Faq">
          <i className="ft-faq" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Faq</span>
        </a>
      </li>

      <li className={this.activeMenu('Aifeed')}>
        <a href="/aifeed">
          <i className="ft-faq" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Manage AI Feed</span>
        </a>
      </li>
      <li className={this.activeMenu('fonts')}>
        <a href="/fonts">
          <i className="ft-type"></i>
          <span className="menu-title" data-i18n="">Fonts</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('templates')}>
        <a href="/templates">
          <i className="ft-feather"></i>
          <span className="menu-title" data-i18n="">Templates</span>
        </a>
        </li>:''} 
	    <li className={this.activeMenu('setting')}>
        <a href="/setting">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Settings</span>
        </a>
      </li>
      
    </ul>
    </Scrollbars>
  </div>
</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;