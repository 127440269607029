import React, { Component } from "react";

class ImageCropper extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const image = this.imageRef.current;

    if (image.complete) {
      this.trimImage(image);
    } else {
      image.onload = () => {
        this.trimImage(image);
      };
    }
  }

  trimImage = (image) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas size
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    ctx.drawImage(image, 0, 0);

    // Get pixel data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;

    let top = canvas.height, bottom = 0, left = canvas.width, right = 0;

    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const index = (y * canvas.width + x) * 4;
        if (pixels[index + 3] > 0) { // Alpha > 0 means pixel is not transparent
          top = Math.min(top, y);
          bottom = Math.max(bottom, y);
          left = Math.min(left, x);
          right = Math.max(right, x);
        }
      }
    }

    // Get dimensions of the trimmed image
    const trimmedWidth = right - left + 1;
    const trimmedHeight = bottom - top + 1;

    // Draw cropped area onto the visible canvas
    const outputCanvas = this.canvasRef.current;
    const outputCtx = outputCanvas.getContext("2d");

    outputCanvas.width = trimmedWidth;
    outputCanvas.height = trimmedHeight;

    outputCtx.drawImage(
      canvas, left, top, trimmedWidth, trimmedHeight, 
      0, 0, trimmedWidth, trimmedHeight
    );

    // Ensure rendering is complete before converting to Blob
    requestAnimationFrame(() => {
      this.handleBlobHere();
    });
  };

  handleBlobHere = () => {
    const canvas = this.canvasRef.current;

    // Convert the canvas content to a Blob
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const originalFileName = this.props.imageName || `image-${Date.now()}`; // Fallback in case name is missing
          const pngFile = new File([blob], `${originalFileName}.png`, { type: "image/png" });
          this.props.onTrimmedImage(pngFile, "png", this.props.index, this.props.fileType);
        }
      },
      "image/png"
    );
  };

  render() {
    return (
      <div className="image-view-container">
        {/* Hidden image for processing */}
        <img
          ref={this.imageRef}
          src={this.props.imagePreview}
          crossOrigin="anonymous"
          alt="Source"
          style={{ display: "none" }}
        />

        {/* Canvas to display the trimmed image */}
        <canvas ref={this.canvasRef} style={{ border: "1px solid #ccc" }} />
      </div>
    );
  }
}

export default ImageCropper;
