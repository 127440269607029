import React, { Component } from 'react';
import { Link, } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl,doctorURL} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
  const params = useParams();
  const  navigate = useNavigate();
  
  
  return (
    <WrappedComponent
    {...props}
    params={params}
    navigate ={navigate}
    // etc...
    />
  );
  };
class View extends Component {
  
  constructor(props)
  {
      super(props); 
     
      const RolesId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
      this.state = {
          rolesId:RolesId,
          roles:'',
          selectedmodules:'',
          rolelist:'',
      };
      var qs = require('qs');
      var rolesId = this.state.rolesId;
      axios.get(apiUrl+"company/getroledetail?id="+rolesId).then(res => {
        this.setState({
          roles:res.data.list,
          selectedmodules:res.data.module,
        });
      }); 
  }

  componentDidMount() {
    document.title = PageTitle('Roles View');
  }

  modulelist(){
    var list = this.state.selectedmodules;
    
    if (list != "undefined" && list != null) {
      if (list.length > 0) {
        var count = Object.keys(list).length ;
        if (Object.keys(list).length > 0) {
          const helperlistDetails = list.map(
            (data, Index) => {
              if(data != "undefined" && data != null && data != '')
              {
                if(count>++Index)
                {
                  return (
                    <>{data.label}, </>
                  );
                }else{
                  return (
                    <>{data.label}. </>
                  );
                }
                
              }
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <span> No Result</span>
        );
      }
    }else {
      return (
        <span> No Result </span>
      );
    }
  }

  render() {
    const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="userrolemanagement" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="back-btn-lux">
					         <a onClick={() => navigate(-1)}>Back</a>    
			        </div> 
                <div className="title">
                  <h3>Roles Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b> Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.first_name} {this.state.roles.last_name}</p>
                          </div>
                        </div>
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact number<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.contact}</p>
                          </div>                          
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Roles<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.role_name}</p>
                          </div>                          
                        </div>


                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Modules<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.modulelist()}</p>
                          </div>                          
                        </div>


                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{(this.state.roles.status=='A')?'Active':'Inactive'}</p>
                          </div>                          
                        </div>


                    </div>
                  </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));