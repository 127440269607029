import React, { Component } from "react";

class ImageCropperaddrows extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.imagePreview) {
      this.loadImage();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imagePreview !== this.props.imagePreview) {
      this.loadImage();
    }
  }

  loadImage = () => {
    const image = this.imageRef.current;
    if (image && image.complete) {
      this.trimImage(image);
    } else if (image) {
      image.onload = () => this.trimImage(image);
    }
  };

  trimImage = (image) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    ctx.drawImage(image, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;

    let top = canvas.height,
      bottom = 0,
      left = canvas.width,
      right = 0;

    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const index = (y * canvas.width + x) * 4;
        if (pixels[index + 3] > 0) {
          top = Math.min(top, y);
          bottom = Math.max(bottom, y);
          left = Math.min(left, x);
          right = Math.max(right, x);
        }
      }
    }

    const trimmedWidth = right - left + 1;
    const trimmedHeight = bottom - top + 1;

    const outputCanvas = this.canvasRef.current;
    const outputCtx = outputCanvas.getContext("2d");

    outputCanvas.width = trimmedWidth;
    outputCanvas.height = trimmedHeight;

    outputCtx.drawImage(
      canvas,
      left,
      top,
      trimmedWidth,
      trimmedHeight,
      0,
      0,
      trimmedWidth,
      trimmedHeight
    );

    requestAnimationFrame(() => {
      this.handleBlobHere();
    });
  };

handleBlobHere = () => {
  const canvas = this.canvasRef.current;

  canvas.toBlob(
    (blob) => {
      if (blob) {
        let originalFileName = this.props.imageName || `image-${Date.now()}`;

        // Remove existing extension before appending ".png"
        originalFileName = originalFileName.replace(/\.[^/.]+$/, ""); 

        const pngFile = new File([blob], `${originalFileName}.png`, { type: "image/png" });

        // Call parent function to update state
        this.props.onTrimmedImage(pngFile, this.props.index);
      }
    },
    "image/png"
  );
};

  

  render() {
    return (
      <div className="image-view-container">
        <img
          ref={this.imageRef}
          src={this.props.imagePreview || ""}
          alt="Source"
          style={{ display: "none" }}
        />
        <canvas ref={this.canvasRef} style={{ border: "1px solid #ccc" }} />
      </div>
    );
  }
}

export default ImageCropperaddrows;
