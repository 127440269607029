import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,  scrollToTop} from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL, baseUrl, mediaUrl} from'../Config/Config';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MyEditor from '../Membership/MyEditor';
import { useParams, useNavigate } from 'react-router-dom';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Addimageinputsimple from './Addimageinputsimple';
import CustomSelect from './CustomSelect';
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Modal,
    Table,
    OverlayTrigger,
    ButtonToolbar,
    Tooltip,
    Button
  } from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
   
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Addconfig extends Component {

	fileObj = [];
    fileArray = [];
    imageArray = [];
	fileObj1 = [];
	tryoutfileArray = [];
	tryimageArray = [];

	fileObj2 = [];
	configimageArray=[];
	configfileArray=[];
	constructor(props)
	{
		// CheckAuth();
		super();	
        const configproductimageId = (props && props.params && typeof props.params.ID !== 'undefined') ? props.params.ID : '';
		this.state = {
            configproductimageId: configproductimageId,
			name:'',
			product_code: '',
			price: '',
			qty:'',
			short_description: '',
			description: '',
			product_gallery_image: '',
			tryout_gallery_image: '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
			tryout_image_preview:'',
			tryout_image_name:'',
			tryout_image:'',
			tryout_fileExtension: '',
			trialon: 'main',
			
			configtrialon:'main',
			rows: [], 
			config_gallery_image:'',
            tryoutdata:[],
            data_status:'',
            trial_on:'',
			configproductimglist:'',
			configtryoutimglist: '',
			product_name:'',
			main_image_previews: {},
			selected_main_image: '',
			selected_main_image_name: '',
			selected_main_image_extension: '',
			selected_main_image_id: '',
			config_product_id:'',
			configproductitemimage:[],
			element_type_connectivity:[],
			selectedelmenttypeOption: [],
			seledtedfirtytryout:"",
			tryoutid1:"",
			selectedconfig_product_images_id:"",
			
		};

		
		axios.get(apiUrl+"configureproduct/getconfigproductdetails?id="+this.state.configproductimageId).then(res => {
            this.setState({config_product_id: res.data.configproductsdetail[0].config_product_id});
		});
        axios.get(apiUrl+"configureproduct/listconfigproductsdetailitems?id="+this.state.configproductimageId).then(res => {
					
            this.setState({configproductsdata: res.data.configproductslistview,data_status: res.data.status,});
		});
		this.getcofigimages();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChangeimages = this.handleChangeimages.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }
	componentDidMount() {
		setTimeout(() => {
		  this.getcofigimages();
		  //this.gettryoutimage();
		  this.configfileArray = [];
		  this.setState({ config_gallery_image: '' });
		  $('.success_message').empty();
		}, 2000);
	  }
	
    gettryoutimage =()=> {

        axios.get(apiUrl+"configureproduct/listconfigproductsdetailitems?id="+this.state.configproductimageId).then(res => {
				
            this.setState({configproductsdata: res.data.configproductslistview,data_status: res.data.status,});
		});
    }
	getcofigimages =()=> {

		var qs = require('qs');
		var postObject = {             
		  id: this.state.configproductimageId
		};
	   
		axios.post(apiUrl+"configureproduct/itemview",qs.stringify(postObject)).then(res => {
		  if(res.data.status === 'success'){
			this.setState({ configproductitemimage: res.data.configproductimglistview, configtryoutimglist: res.data.configtryoutlistview ,productdetail:res.data.configproductlistview,data_status: res.data.status});
		  }  
		});  
    }
	uploadMultipleFiles(arg,e){
		
		if (!e || !e.target || !e.target.files) {
			console.error("Invalid event or files not found.");
			return;
		  }
		
		if(arg=='product'){
			this.fileObj = [];
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				this.imageArray.push(this.fileObj[0][i])
			}
			
			this.setState({ product_gallery_image: this.imageArray })
		} else if (arg=='configtryout'){
			this.fileObj2 = [];
			this.fileObj2.push(e.target.files)
			for (let i = 0; i < this.fileObj2[0].length; i++) {
				
				this.configfileArray.push(URL.createObjectURL(this.fileObj2[0][i]))
				this.configimageArray.push(this.fileObj2[0][i])
				
				this.setState({ config_gallery_image: this.configimageArray })
				console.log(this.configimageArray,'images');
			}
		
		}
		
		// else{
		// 	this.fileObj1 = [];
		// 	this.fileObj1.push(e.target.files)
		// 	for (let i = 0; i < this.fileObj1[0].length; i++) {
		// 		this.tryoutfileArray.push(URL.createObjectURL(this.fileObj1[0][i]))
		// 		this.tryimageArray.push(this.fileObj1[0][i])
		// 	}
			
		// 	this.setState({ tryout_gallery_image: this.tryimageArray })
		// }	
	}
	uploadMultipleConfigFiles(arg,e){
		let tryimageArray= e;
		console.log(tryimageArray,'images')
		this.fileObj1 = [];
			this.fileObj1.push(tryimageArray)
			  for (let i = 0; i < this.fileObj1[0].length; i++) {
						this.tryoutfileArray.push(URL.createObjectURL(this.fileObj1[0][i]))
						this.tryimageArray.push(this.fileObj1[0][i])
			  }	
			this.setState({ tryout_gallery_image: this.tryimageArray })
	}
	

	removeImagebyindex = (indexvalue) => {
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	
		for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
		}
	
		this.setState({ product_gallery_image: this.imageArray })
		
	}
	removeImageConfigbyindex = (indexvalue) => {
	
		var list = [...this.configimageArray];
		this.configimageArray = [];
		this.configfileArray = [];
		list.splice(indexvalue, 1);
	
		for (let i = 0; i < list.length; i++) {
		this.configimageArray.push(list[i]);
		this.configfileArray.push(URL.createObjectURL(list[i]));
		}
	
		this.setState({ config_gallery_image: this.imageArray })
		
	}
	removeTryoutImagebyindex = (indexvalue) => {
		var trylist = [...this.tryimageArray];
		this.tryimageArray = [];
		this.tryoutfileArray = [];
		trylist.splice(indexvalue, 1);

		for (let i = 0; i < trylist.length; i++) {
		this.tryimageArray.push(trylist[i]);
		this.tryoutfileArray.push(URL.createObjectURL(trylist[i]));
		}

		this.setState({ tryout_gallery_image: this.tryimageArray })
	}

  	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}
	uploadImage = (file, selectedid) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
		let formData = new FormData();
		formData.append('admin_id',localStorage.getItem("admin_id"))
		formData.append('image',file);
		formData.append('tryoutid',selectedid);
		axios.post(apiUrl+"configureproduct/addmainimage",formData,config).then(res => {
			if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				scrollToTop();
				
				// setTimeout(
						// function() {
						// 	//this.props.navigate('/config-product');
						// }
						// .bind(this),
						// 3000
						// );
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});

	}
	onSelcectImage = (arg, selectedid, event) => {  // Corrected method name from onSelcectImage to onSelectImage
      
        let reader = new FileReader();
        const file = event.target.files[0];  // Ensure event is passed correctly in your onChange handler
        const extension = file.name.split('.').pop();
        reader.onloadend = () => {
            // Update image previews for the specific row
            this.setState((prevState) => ({
                main_image_previews: {
                    ...prevState.image_previews,
                    [selectedid]: reader.result, 
                },
				selected_main_image: file,  // Store selected file in state if needed
				selected_main_image_name: file.name,
				selected_main_image_extension: extension,
				selected_main_image_id: selectedid,
            }));
			
			this.uploadImage(file,selectedid);
        };
        reader.readAsDataURL(file);
    };
	
handleInputChange = (event) => {
  const { name, value, type, checked } = event.target;
  const newValue = type === 'checkbox' ? (checked ? value : 'main') : value;
  this.setState({
    [name]: newValue
  });

  if (newValue === 'element') {
    this.gettryoutimage(value);
  } else if (newValue === 'main') {
    this.gettryoutimage(value);
  }
}




    
	handleRowChange = (rowIndex, updatedRow) => {
        
		this.setState((prevState) => {
			const updatedRows = [...prevState.rows];
			updatedRows[rowIndex] = updatedRow;
			return { rows: updatedRows };
		});
		
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.shouldRerender !== prevState.shouldRerender && this.state.shouldRerender) {
			// Reset the shouldRerender state to false
			this.setState({
				shouldRerender: false
			});
			this.forceUpdate();
			
		}
	}
  	handleSubmit = () => {
		//if(this.validateForm()){
			const payload = this.state;

			console.log(payload,'this is payload data')
			var qs = require('qs');
			var status = '';

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}
			let formData = new FormData();
			var imagedata = payload.product_gallery_image;
			for (var i in imagedata) {
				formData.append('images[]',imagedata[i])
			}

			var configimagedata = payload.config_gallery_image;
			for (var i in configimagedata) {
				formData.append('configimages[]',configimagedata[i])
			}
			// var tryimagedata = payload.tryout_gallery_image;
			// for (var i in tryimagedata) {
			// 	formData.append('tryoutimages[]',tryimagedata[i])
			// }
			
           
			if(payload.selectedvalue === ''){
				status =payload.selectedOption.value;
			}else{
				status = payload.selectedvalue;
			}
			

			
			const rowsJson = JSON.stringify(payload.rows);
			// payload.rows.forEach((row, index) => {
			// 	if (row.configImages?.thumb) {
			// 	  formData.append(`configthumbimages[${index}][thumb]`, row.configImages.thumb);
			// 	} else if (row.images?.thumb) {
			// 	  formData.append(`configthumbimages[${index}][thumb]`, row.images.thumb);
			// 	}
			  
				
			// 	if (row.configImages?.original) {
			// 	  formData.append(`configoriginalimages[${index}][original]`, row.configImages.original);
			// 	} else if (row.images?.original) {
			// 	  formData.append(`configoriginalimages[${index}][original]`, row.images.original);
			// 	}
			//   });

			payload.rows.forEach((row, index) => {
				formData.delete(`configthumbimages[${index}][thumb]`);
				formData.delete(`configoriginalimages[${index}][original]`);
			  
				if (row.configImages?.thumb) {
				  formData.append(`configthumbimages[${index}][thumb]`, row.configImages.thumb);
				}
			  
				if (row.configImages?.original) {
				  formData.append(`configoriginalimages[${index}][original]`, row.configImages.original);
				}
			  });
			  
			formData.append('admin_id',localStorage.getItem("admin_id"))
            formData.append('configproductId',payload.config_product_id)
			formData.append('configproductimageId',payload.configproductimageId)
			
			
			formData.append('rowvalue',rowsJson)
			axios.post(apiUrl+"configureproduct/addorginalimages",formData,config).then(res => {
				if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					this.setState({
						shouldRerender: true,
						category_product_price:"",
						category_product_name:"",
						rows:[],
					});	
					setTimeout(
							function() {
								this.getcofigimages();
								this.gettryoutimage();
								this.configfileArray = [];
								this.setState({config_gallery_image:''});
								///window.location.reload();
								$('.success_message').empty();
							}
							.bind(this),
							2000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		//}
		
	}

	handleSubmitConnectivity = () => {
		//if(this.validateForm()){
			const payload = this.state;

			
			var qs = require('qs');
			var status = '';

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}
			let formData = new FormData();
			
			
			
           
			

			
		
			formData.append('admin_id',localStorage.getItem("admin_id"))
           
			formData.append('tryouttid1',payload.tryoutid1)
			formData.append('tryouttid2',payload.selectedelmenttypeOption.value)
			formData.append('configproductimageId',payload.configproductimageId)
			formData.append('connectivity_update_status','yes')
			
			axios.post(apiUrl+"configureproduct/addconnectivityimages",formData,config).then(res => {
				if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					this.setState({
						shouldRerender: true,
						category_product_price:"",
						selectedelmenttypeOption:"",
						selectedOption:"",
						category_product_name:"",
						connectivitySt:false,

					});	
					setTimeout(
							function() {
								this.getcofigimages();
								this.gettryoutimage();
								this.configfileArray = [];
								this.setState({config_gallery_image:''});
								///window.location.reload();
								$('.success_message').empty();
							}
							.bind(this),
							2000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		//}
		
	}
	

	validateForm() {
		const {name,product_code,price,image,product_gallery_image,tryout_image,tryout_gallery_image,qty ,config_gallery_image} = this.state;
		const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let errors = {};
      	let formIsValid = true;

		let maxSize = 9000000;//1 mb
		console.log(this.state.trialon ,'trion_onevali')
		if(this.state.trialon === 'main') {
			if (!config_gallery_image) {
				formIsValid = false;
				$('.errorconfig_image').html('<span class="errorspan">Please fill the field</span>');
			}else if(config_gallery_image){
				$('.errorconfig_image').html('');
			}
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handleChangeFromChild = (data) => {
		console.log(data.rows, 'alldata');
		const { rows } = this.state;

	
		const updatedRows = data.rows.filter(
			(row) =>
				!rows.some(
					(r) =>
						r.category_product_name === row.category_product_name &&
						r.category_product_price === row.category_product_price &&
						r.selectedOption.value === row.selectedOption.value
				)
		);
		if (updatedRows.length > 0) {
			this.setState(
				{
					rows: [...rows, ...updatedRows] // Add new rows to the existing rows
				},
				() => console.log(this.state.rows, 'State after update') // Log the updated state
			);
		} else {
			console.log('No new rows to update');
		}
	}


	productlist() {
		var productlist = this.state.configproductitemimage;

		var data_status = this.state.data_status;
		if (productlist != "undefined" && productlist != null) {
		  
		//   if (data_status == "success") {
  
			 if (Object.keys(productlist).length > 0) {
			  const listDetails = productlist.map(
				(productlist, Index) => {
				  let sno = Index+1;
				 
  
				  
				  const images = [
					productlist.config_image,
					  
				  ];
				  const imageList = images.map((image, i) => (
					  <img
						  key={i}
						  className="config-list"
						  src={mediaUrl + productlist.config_image_path + image}
						  width={200}
						  height={200}
						  alt={`Image ${i+1}`}
					  />
				  ));
				  
				 
					  return (
						<div>
							 <p><strong>Product Name: </strong>{productlist.product_name}</p>
							 <p><strong>Product Code: </strong>{productlist.product_code}</p>
							 <p>{imageList}</p>
						</div>
						);
				 
				  
				}
			  );
			  return listDetails;
			// }
			// else {
			//   return (
			// 	<tr>
			// 	  <td colspan="11" className="v-align-nr">
			// 		No Result
			// 	  </td>
			// 	</tr>
			//   );
			// }
		  } 
		  
		}
	}
	confirmPopup = (id) => {
		
		
		var	status="Delete";
		  
		this.setState({selectid: id, selectedStatus: status})	
		$('.confirm-action').addClass('show');		
	  }

	  confirmremoveconnectivityPopup = (id,product_images_id) => {
		
		
		var	status="Remove Connectivity";
		  
		this.setState({selectid: id,selectedconfig_product_images_id:product_images_id, selectedStatus: status,})	
		$('.confirm-action').addClass('show');		
	  }


	  

	  confirmPopupConnectivity = (tryout_id,config_product_id) => {
		this.setState({ config_product_id: config_product_id });


		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
		this.setState({tryoutid1: tryout_id})	
	 
		let formData = new FormData();
		formData.append('admin_id',localStorage.getItem("admin_id"));
		formData.append('tryoutid',tryout_id);

		formData.append('config_product_id',config_product_id);
		formData.append('configproductimageId',this.state.configproductimageId);
		axios.post(apiUrl+"configureproduct/gettryoutconnectivity",formData,config).then(res => {
			if(res.data.status === "success"){
				const configItems = res.data.configprodutitems;
				const processedItems = Array.isArray(configItems)
					? configItems.map((item) => ({
						  value: item.tryout_id,
						  label: item.element_category,
					  }))
					: [
						  {
							  value: configItems.tryout_id,
							  label: configItems.element_category,
						  },
					  ];
					  this.setState({
						element_type_connectivity: processedItems,
					});
			
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
			}
		});
		
		var	status="Connectivity";

		this.setState({selectid: tryout_id, selectedStatus: status,connectivitySt:true})	
		
	  }

	  
	configproductlist() {
		var configproductlist = this.state.configproductsdata;
		var data_status = this.state.data_status;
	
		if (Array.isArray(configproductlist) && configproductlist.length > 0) {


			const uniqueCategories = [
                ...new Set(configproductlist.map((item) => item.element_category)),
            ];
			if (data_status === "success") {
				return configproductlist.map((item, index) => {
					
					let sno = index + 1;
					const imageList1 = (
						<img
							key={index}
							className="config-list"
							src={mediaUrl + item.tryout_image_path + item.tryout_image}
							width={200}
							height={200}
							alt={`Image ${index + 1}`}
						/>
					);
					const originalimage = (
						<img
							key={index}
							className="config-list"
							src={mediaUrl+'/' + item.org_image_path+ item.org_image}
							width={200}
							height={200}
							alt={`Image ${index + 1}`}
						/>
					);
					
					return (
						<tr key={item.id}>
							<td>{sno}</td>
							<td>{item.product_name || "N/A"}</td>
							<td>{item.element_category ? item.element_category.charAt(0).toUpperCase() + item.element_category.slice(1) : "N/A"}</td>
							<td>${item.price || "N/A"}</td>
							<td>{imageList1}</td>
							<td>{originalimage}</td>
							<td> 
							

								<Link onClick={this.confirmPopup.bind(this,item.tryout_id)} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
								
								
								{item.connectivity_update_status == 'no' &&  uniqueCategories.length === 2 && (
							
								<Link onClick={this.confirmPopupConnectivity.bind(this,item.tryout_id,item.config_product_id)} className="" title="Connectivity">
									<img
										key={index}
										className="config-list"
										src={mediaUrl+'/' + 'link.png'}
										width={30}
										height={30}
										alt={`Image}`}
									/>
									</Link>
								)}


							{uniqueCategories.length === 2  && item.connectivity_update_status == 'yes'  && (
								<Link onClick={this.confirmremoveconnectivityPopup.bind(this,item.tryout_id,item.config_product_images_id)} className="" title="Remove Connectivity">
									<img
										key={index}
										className="config-list"
										src={mediaUrl+'/' + 'connectionremove.png'}
										width={30}
										height={30}
										alt={`Image}`}
									/>
									</Link>
									)}
								</td>
							
						</tr>
					);
				});
			} else {
				return (
					<tr>
						<td colSpan="5" className="v-align-nr">
							No Result
						</td>
					</tr>
				);
			}
		} else {
			return (
				<tr>
					<td colSpan="5" className="v-align-nr">
						No Result
					</td>
				</tr>
			);
		}
	}
	tryoutthumblist() {
			let configproductimglist = this.state.configtryoutimglist;
			let tryoutthumbimage = configproductimglist.length > 0 && configproductimglist.map((img, index) => {
				if(img.type === 'thumb') {
					let thumb_img = img.tryout_image;
					let thumb_img_path = img.tryout_image_path;
					return (
						<img src={mediaUrl + thumb_img_path + thumb_img} width={200} height={200} alt={"Thumb Image" + (index + 1)} />
					);
				}
			});
			// Check if there are thumbnail images to display
			if (tryoutthumbimage && tryoutthumbimage.length > 0) {
				return (
					<>
						<p><b>Element Images:</b></p>
						{tryoutthumbimage}
					</>
				);
			} else {
				return null; // Return null if there are no thumbnail images
			}
		}

		removeproductconnections(){
			var qs = require('qs');
			var postObject = {
			  admin_id : localStorage.getItem("admin_id"),
			  id  :this.state.selectid,
			  config_product_images_id  :this.state.selectedconfig_product_images_id,
			  
			};
			
			axios.post(apiUrl+"configureproduct/remove_connectivity",qs.stringify(postObject)).then(res => {
			if(res.data.status === 'success'){  
			  $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
				setTimeout(
				  function() {
					$('.success_message').html('');
					this.gettryoutimage();
				  }
				  .bind(this),
				  3000
				  );     
				  
			}
			}); 
			}


		deletetab(){
			var qs = require('qs');
			var postObject = {
			  admin_id : localStorage.getItem("admin_id"),
			  id  :this.state.selectid,
			};
			
			axios.post(apiUrl+"configureproduct/thumb_image_delete",qs.stringify(postObject)).then(res => {
			if(res.data.status === 'success'){  
			  $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
				setTimeout(
				  function() {
					$('.success_message').html('');
					this.gettryoutimage();
				  }
				  .bind(this),
				  3000
				  );     
				  
			}
			}); 
			}
		
Confirmstatus =(confirmstatus)=>{
	
	if(confirmstatus == 'yes'){


		if(this.state.selectedStatus=="Delete"){
		  this.deletetab()
		} else if (this.state.selectedStatus=="Remove Connectivity"){
			this.removeproductconnections();
		}
		$('.confirm-action').removeClass('show');
	}else{
	  $('.confirm-action').removeClass('show');	
	}
  }


  Connectivityclose =()=>{
	this.setState({connectivitySt: false});
	
  }
  
  

  handleChangeimages(selectedOption) {
		this.setState({selectedelmenttypeOption: selectedOption});	
  }
  handleChange(selectedOption) {
	this.setState({selectedOption: selectedOption,seledtedfirstytryout:selectedOption.value});


	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}
	let formData = new FormData();
	formData.append('admin_id',localStorage.getItem("admin_id"));
	formData.append('tryoutid',selectedOption.value);
	axios.post(apiUrl+"configureproduct/gettryoutconnectivityimages",formData,config).then(res => {
		if(res.data.status === "success"){
			const configItems = res.data.configprodutitems;

			
            
			const processedItems = Array.isArray(configItems)
				? configItems.map((item) => ({
					  value: item.tryout_id,
					  label: item.product_name,
					  image: mediaUrl + item.tryout_image_path + item.tryout_image,
				  }))
				: [
					  {
						  value: configItems.tryout_id,
						  label: '',
						  image: mediaUrl + configItems.tryout_image_path + configItems.tryout_image,
					  },
				  ];
				  this.setState({
					element_type_image: processedItems,
				});
		
		}else{
			$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
			
			setTimeout(
			function() {
				$('.success_message').html('');
			}
			.bind(this),
			3000
			);
		}
	});
	
  }

  render() {
	const tableData = this.configproductlist();

		{console.log(this.state.productdetail,'productdetails')}
	const navigate = this.props.navigate;
	const {selectedOption} = this.state;

	let imageslist = [];
	let indexlength =  imageslist.length;

	const imgThumblist =  this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index,'prodcut')}>X</a>
		</li>
	));

	const imgtryoutThumblist = this.tryoutfileArray.map((url,index) => (
		<li className="thumb" key={index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeTryoutImagebyindex.bind(this,index,'tryout')}>X</a>
		</li>
	));

	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	// var	tryoutpreview = <img className="img_class" src={this.state.tryout_image_preview} alt="" />;

		
	const tryoutpreview =  this.configfileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImageConfigbyindex.bind(this,index,'configtryout')}>X</a>
		</li>
	));

	            // this.configfileArray.push(URL.createObjectURL(this.fileObj2[0][i]))
				// this.configimageArray.push(this.fileObj2[0][i])
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="config-product-items" />  

		<div id="location-error"></div>
		<div className="content">	
			<div className="content-wrapper">
				<div class="content-wrapper-before"></div>
					<div className="form-wrapper leaa-admin-head card">
					<div className="success_message"></div>
					<div className="back-btn-lux">
						<a onClick={() => navigate(-1)}>Back</a>    
					</div>
					<br></br>
					<br></br>
					<div className="form-row">
					<div className="form-left">
						{this.productlist()}
						{/* {this.tryoutthumblist()}	 */}
				     </div>
					</div>
					
					<div className="title">
						<h4>Add Configure Products</h4>				
					</div>
					<form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
						<div className="form-row">
							<div className="form-left">
							</div>
							<div className="form-right">
							</div>	
						</div>	
						<div className="form-row">
							<div className="form-group"> 
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn">
										<Addimageinputsimple
											onChange={(e) => this.uploadMultipleConfigFiles('tryout', e)}
									  	     imageList={imgtryoutThumblist}
											 trialon={this.state.trialon}
											 onChangeRow={this.handleRowChange}
											 shouldRerender={this.state.shouldRerender}
                                             onChangeChild={(data) => this.handleChangeFromChild(data)}
									   	/>
										</span>
									<div className="errortryout_image"></div>
								</div>
						</div>
						
							<div className="btn-group">	
							 <button className="btn btn_orange test-vi1 btn_minwid login_submit animate-btn2" type="submit" >	Submit</button>
						</div>
						
						
					</form>
					<div className="overflow">
				
			{tableData.length > 0 && (		 
                <Table striped bordered hover>
                  <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Product Name</th>
						<th>Element</th>
						<th>Price</th>
                        <th>Thumb Image</th>
						<th>Original Image</th>
						<th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.configproductlist()}</tbody>
                </Table>
				   )}
					<div>
						<div className="view-details-two">
							
							
						</div>
						<div className="view-details-two">
							
							
						</div>
					</div>
					
                 {/* )} */}
			</div>
				</div>
			</div>	
		</div>
		<div>

		
		{this.state.connectivitySt ? (

				<div className={`confirm-action config-popup ${this.state.connectivitySt ? 'show' : ''}`}>
				<a href="javascript://" className='popup-close-btn' onClick={this.Connectivityclose.bind(this)} >X</a>
				<div className='form-row'>
				<div className='form-left'>
					<h4>Product Connectivity</h4>
					<div className="form-group">
						    <label>Element Type<span class="required" style={{ color: "red" }} > * </span> : </label>
							<Select
								value={this.state.selectedOption ? this.state.selectedOption : null}
								options={this.state.element_type_connectivity} 
								onChange={(selectedOption) => this.handleChange(selectedOption)} 
							/>
							<div className="errorcate"></div>
					</div>
								<div className="form-group">
								<CustomSelect
									value={this.state.selectedelmenttypeOption ? this.state.selectedelmenttypeOption : null}
									options={this.state.element_type_image}
									onChange={this.handleChangeimages}
									/>
								</div>

								
							</div>
							</div>
							<div className="btn-group export">
									<button className="btn btn_orange btn_minwid login_submit animate-btn2"  onClick={this.handleSubmitConnectivity.bind(this)} type="submit"> Submit</button>
								</div>
							</div>
			) : (
				
					<div className="confirm-action">
									<p>Are you sure! you want to {this.state.selectedStatus} the config images?</p>
									<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
									<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
					</div>
				 

				)}
			</div>
		

	
					
					
    </div>
    );
  }
}


export default (withRouter(Addconfig));